/* for font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* for slick slider */
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css");

:root {
  --green-color: #039646;
  --yellow-color:#feed01;
  --lightyellow-color:#feed0165;
  --brown-color:#8a231c;
  --black-color:#000;
  --white-color:white;
  --sky-color:#d8ebe5;
  --gray-color:#ccc;
  --darkgray-color:#8b8a8a;
  --section-space: 120px;
	--section-space-mobile: 80px;
	--section-title-space: 70px;
  --font-family:"Roboto", sans-serif;
}


* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.container-fluid{
  padding: 0px 45px;
}

/* start scrollbar */
body {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth !important;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

body::-webkit-scrollbar-track {
  background: var(--lightyellow-color);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--green-color);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.3) 25%,
    transparent 20%,
    transparent 50%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.3) 75%,
    transparent 75%,
    transparent
  );
  border-radius: 20px;
}

/* end scrollbar */

.bg-g,
bg-success {
  background: green;
}

.text-g {
  color: var(--green-color);
}

.container-fluid {
  padding: 0px 45px;
}

.cursor {
  cursor: pointer;
}

.hd {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 25px;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.price {
  font-size: 18px;
  margin-right: 7px;
}

.oldPrice {
  font-size: 14px;
  opacity: 0.8;
  color: #000;
  margin-right: 4px;
  text-decoration: line-through;
}

.listingPage {
  width: 100%;
  height: auto;
  /* padding: 45px 0px; */
}

/* breadcrumb1 start */

.breadcrumb1 {
  width: 100%;
  height: 200px;
  padding: 70px 80px;
  background: url("./assets/img/breadcrumb-bg.png");
  border-radius: 20px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 30px;
  background-size: cover;
}

.breadcrumb1 h3 {
  color: var(--white-color);
  font-size: 42px;
  font-weight: 700;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.breadcrumb1 ul li {
  margin-right: 15px !important;
  color: var(--white-color);
}

.breadcrumb1 ul li a {
  color: var(--yellow-color);
  text-decoration: none;
  font-size: 20px;
}

.breadcrumb1 ul li a:hover{
  color: var(--white-color);
}

/* Responsive Styles */

/* Desktop and Laptop (larger screens) */
@media (min-width: 992px) {

}

/* Tablet and Mobile (medium and smaller screens) */
@media (max-width: 991px) {

}

@media (max-width: 768px) {

}

@media (max-width: 576px) {
  .breadcrumb1 {
  background: url("./assets/img/breadcrumb546.png");
  }
  .breadcrumb1 h3 {
    font-size: 25px;
    margin-top: -25px;
}
} 

.sidebarWrapper h3 {
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

/* breadcrumb1 end */

.sidebarWrapper h3:after {
  content: "";
  width: 100%;
  height: 3px;
  background: #ccc;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.sidebarWrapper h3:before {
  content: "";
  width: 30%;
  height: 3px;
  background: green;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 9;
}

.sidebarWrapper .sidebar{
  position: sticky;
  top: 100px;
}

.sidebarWrapper .sidebar .card {
  padding: 25px;
  border-radius: 10px !important;
  margin-bottom: 40px;
}

.sidebarWrapper .sidebar .card .catList .catItem {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 10px;
  margin: 3px 0px;
  transition: all 0.3s ease-in-out;
}

.sidebarWrapper .sidebar .card .catList .catItem:hover {
  border: 1px solid #bcec9b;
}

.sidebarWrapper .sidebar .card .catList .catItem h4 {
  color: #000;
  font-size: 15px;
}

.rounded-circle {
  background: #bcec9b;
  width: 30px;
  height: 30px;
}
