header{
    width: 100%;
    height: auto;
    margin: auto;
    padding: 20px 0px;
    background: #fff;
    border-bottom: 1px solid #056839;
}

 .headerWrapper {
    transition: all 0.3s ease-in-out;
    background: #fff;
}

.headerWrapper.fixed {
    position: fixed;
    top: -100px;
    left: 0px;
    width: 100%;
    z-index: 999;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.09);
}

header .headerSearch {
    width: 100%;
    height: 45px;
    border: 1px solid #056839;
    padding: 10px 15px;
    border-radius: 5px;
}

header .headerSearch .selectDrop{
    width: 30%;
    font-size: 18px;
    font-weight: 500;
  
}

header .headerSearch .selectDrop:after{
    content: '';
    width: 1px;
    height: 30px;
    background: rgba(0,0,0,0.2);
    position: absolute;
    top: -2px;
    right: 0px;
}

header .headerSearch .search{
    width: 70%;
    padding-left: 25px;
    position: relative;
}

header .headerSearch .search input{
    width: 100%;
    height: 40px;
    border: 0px;
    outline: none !important;
    font-size: 18px;
}

header .headerSearch .search .searchIcon{
    position: absolute;
    top: 8px;
    right: 7px;
    opacity: 0.7;
}


header .headerTabs{
    padding-left: 50px;
    margin-top: 13px;
}

header .headerTabs li{
    position: relative;
}

header .headerTabs li span{
    font-size: 18px;
    cursor: pointer;
}

header .headerTabs li span .icon{
    margin-right: 8px;
}

header .headerTabs li span .icon:hover{
   color:var(--green-color);
}

header .headerTabs li span.badge{
    width: 20px;
    height: 20px;
    color: #fff;
    font-weight: 200;
    position: absolute;
    top: -8px;
    left: 10px;
    font-size: 14px;
    text-align: center;
}

.dropDownMenu{
    position: absolute;
    top: 150%;
    right: 0px;
    width: 180px;
    height: auto;
    background:#fff;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.05);
    border-radius: 5px;
    z-index: 999;
}

.dropDownMenu li{
    width: 100%;
    list-style: none;
    margin: 0px !important;
}

.dropDownMenu li Button{
    width: 100% !important;
    text-align: left !important;
    justify-content: flex-start !important;
    color: #000 !important;
    text-transform: capitalize !important;
    outline: none;
    padding: 10px 15px !important;

}

.addCart{
    color: black;
}

.addCart:hover{
    text-decoration: none;
    color: var(--green-color);
    font-weight: 500;
}

/* Hide elements by default on mobile */


/* Adjustments for tablets */
@media (max-width: 768px) {
    .headerTabs {
        padding-left: 20px;
    }
}
