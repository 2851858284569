@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css");

.catSliderSection {
  width: 100%;
  height: auto;
  padding: 30px 0px;
}

.catSliderMain .slick-slide {
  padding-right: 20px !important;
}

.catSliderMain .item {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 0px;
}

.catSliderMain .item:hover .info {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transform: translateY(-10px);
}

.catSliderMain .item .info {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.catSliderMain .item .info h6 {
  font-size: 12px;
  font-weight: 600 !important;
}

.catSliderMain .item .info p {
  font-size: 14px;
  opacity: 0.8;
  color: #000;
  margin-bottom: 5px;
}

.catSliderMain .slick-arrow {
  background-color: var(--green-color);
  z-index: 9;
}

.catSliderMain .slick-arrow.slick-next {
  top: -50px !important;
  right: 20px !important;
}

.catSliderMain .slick-arrow.slick-prev {
  top: -50px !important;
  right: 80px !important;
  left: inherit !important;
}

@media (max-width: 767px) {
  .catimg {
    height: 80px;
    width: 85px;
  }
}

@media (max-width: 576px) {
  .catimg {
    height: 80px;
    width: 85px;
  }
  .catSliderMain .item .info h6 {
    font-size: 12px;
  }
  .catSliderMain .item .info p {
    font-size: 10px;
  }

  .catSliderMain .item .info {
    padding: 35px;
  }
}
