
.priceRange span{
    font-size: 18px;
}

.filters{
    padding: 25px 0px;
}

.filters h5{
    font-weight: 500;
    margin-bottom: 10px;
}

.filters ul{
    max-height: 200px;
    overflow-y: scroll;
}

.filters ul::-webkit-scrollbar{
    width: 7px !important;
}

.filters ul::-webkit-scrollbar-thumb{
    background: #ccc !important;
}

.filters ul li{
    width: 100%;
    list-style: none;
    margin-bottom: 0px;
}

.sideimg{
    border-radius: 10px;
}


