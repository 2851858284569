.about h2 {
  margin-bottom: 15px;
  color: black;
}

.about p {
  text-align: justify;
}

.about .bimg {
  height: 670px;
  margin-top: 68px;
  margin-left: -40px;
}

.about .title.style-3 {
  background: url("../../../assets/img/wave.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 25px;
  margin-top: 40px;
}

.about .featured {
  margin-left: -40px;
  margin-right: -40px;
}

.about .featured-card {
  padding: 50px 30px;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: var(--white-color);
  margin-top: 30px;
}

.about .featured-card:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.about .featured-card img {
  margin-bottom: 30px;
  width: 100px;
  transition: all 0.3s ease-in-out;
}

.about .featured-card:hover img {
  transform: translateY(-5px);
}

.about .featured-card h4 {
  margin-bottom: 30px;
}

.about .featured-card p {
  font-size: 17px;
  margin-bottom: 30px;
  text-align: justify;
}

.about .featured-card a {
  font-size: 16px;
  color: var(--green-color);
}

.about .featured-card a:hover {
  color: var(--yellow-color);
}

.about bimg2 {
  margin-left: -40px;
}

.about .title.style-4 {
  background: url("../../../assets/img/wave.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 25px;
  margin-top: 40px;
  text-align: center;
  font-size: 30px;
}

.about .whoweare2 a {
  font-size: 16px;
  color: var(--green-color);
}

.about .whoweare2 a:hover {
  color: var(--yellow-color);
}

/* Responsive Styles */

/* Desktop and Laptop (larger screens) */
@media (min-width: 992px) {
  .about .bimg {
    margin-left: -40px;
  }

  .about .featured {
    margin-left: -40px;
    margin-right: -40px;
  }
}

/* Tablet and Mobile (medium and smaller screens) */
@media (max-width: 991px) {
  .about .bimg {
    padding-left: 125px;
    padding-right: 75px;
    margin-bottom: 30px;
  }

  .about .featured {
    margin-left: 0;
    margin-right: 0;
  }

  .about .featured-card {
    padding: 30px 20px;
    margin-top: 20px;
  }

  .about .featured-card img {
    width: 80px;
  }

  .about .title.style-4 {
    font-size: 24px;
  }
}

p {
  padding-left: 20px;
  padding-right: 20px;
}

.mb-30 {
  margin-left: 20px;
}

h2 {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .about h2 {
    font-size: 24px;
    margin-left: 20px;
  }

  .about p {
    padding-left: 20px;
    padding-right: 20px;
  }

  .about .featured-card {
    padding: 20px 15px;
  }

  .about .featured-card img {
    width: 60px;
  }

  .about .featured-card h4 {
    font-size: 20px;
  }

  .about .featured-card p {
    font-size: 16px;
  }

  .mb-30 {
    margin-left: 20px;
  }

  .about .title.style-4 {
    font-size: 20px;
  }
  .about .bimg {
    padding-left: 82px;
    padding-right: 82px;
    margin-top: 0px;
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .about h2 {
    margin-top: -30px;
    font-size: 30px;
    margin-left: 38px;
  }

  .about p {
    padding-left: 40px;
    padding-right: 40px;
  }

  .about .featured-card {
    padding: 15px 10px;
  }

  .about .featured-card img {
    width: 50px;
  }

  .about .featured-card h4 {
    font-size: 18px;
  }

  .about .featured-card p {
    font-size: 14px;
  }

  .about .title.style-4 {
    font-size: 18px;
  }

  .about .bimg {
    /* padding-left: 15%; */
    padding-right: 0%;
    height: 80%;
    margin-bottom: 25px;
}

  .about .bimg2 {
    padding: 15px;
  }

  

  .bimgdiv{
    /* margin-bottom: -128px; */
  }
}
