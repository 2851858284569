.detailsPage .detailsContainer{
    max-width: 90%;
    padding: 30px 0px;
}

.detailsPage .part1 .productZoom{
    width: 100%;
    height: auto;
    border: 1px solid var(--green-color);
    padding: 15px;
    border-radius: 10px;
}

.detailsPage .part1 .zoomSlider{
    padding-top: 30px !important;
}

.detailsPage .part1 .zoomSlider .slick-slide{
    padding-right: 10px !important;
}

.detailsPage .part1 .zoomSlider .item{
    overflow: hidden;
    border-radius: 15px;
}

.detailsPage .part1 .zoomSlider .slick-current .item{
    border: 3px solid var(--green-color) !important;
}

.detailsPage .part1 .zoomSlider .slick-arrow{
    top: 65% !important;
    zoom: 80%;

}

/* product information start here */

.detailsPage .productInfo h1{ 
    font-size: 45px;
    font-weight: bold;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

/* .css-15befwp-MuiRating-root classname from material ui */
.detailsPage .productInfo .css-15befwp-MuiRating-root{
    font-size: 1.1rem
}

.detailsPage .productInfo .priceSec .priceLarge{
    font-size: 42px;
    font-weight: bold;
    color: var(--green-color);
}

.detailsPage .productInfo .priceSec .pricePerOff{
    font-size: 14px;
    color: orange;
}

.detailsPage .productInfo .priceSec .priceSmall{
    font-size: 20px;
    font-weight: 600;
    color: var(--black-color);
    text-decoration: line-through;
}


.detailsPage .productInfo .short-desc{
    font-size: 16px;
    text-align: justify;

}

.detailsPage .productInfo .proudctSize{
    width: 100%;
    height: auto;
    padding: 25px 0px;
}

.detailsPage .productInfo .proudctSize ul li{
    margin-right: 15px !important;
}

.tag{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px !important;
    cursor: pointer;
    text-decoration: none !important;
    font-size: 16px !important;
    transition: all 0.1s ease-in-out;
}

.addCartSection{
    width: 100%;
    height: auto;
}

.addCartSection .counterSec{
    width: 100px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--green-color) !important;
    overflow: hidden;
    padding: 10px;
    position: relative;
}

.addCartSection .counterSec input{
    width: 60px;
    height: 30px;
    padding: 5px ;
    border: 0px;
    outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.addCartSection .counterSec .arrow{
    position: absolute;
    cursor: pointer;
    opacity: 0.5;
}

.addCartSection .counterSec .arrow.plus{
    top:0px;
    right:10px;

}

.addCartSection .counterSec .arrow.minus{
    bottom: 0px;
    right: 10px;
}

.addCartSection button{
    height: 50px;
    outline: none;
    background: var(--green-color);
    color: var(--white-color);
    text-align: center;
    border-radius: 10px; 
}

.addCartSection button:hover{
    background: var(--yellow-color);
    color: var(--green-color);
}

.detailsPageTabs{
    border-radius: 25px !important;
}

.customTabs ul li button{
    padding: 5px 15px !important;
    border: 1px solid var(--gray-color) !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
    border-radius: 30px !important;
    outline: none;
    color: var(--green-color);
}
