.notFound img{
    height: 440px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 315px;
    position: relative;
}

.notFound a{
    position: absolute;
    color: var(--white-color);
    text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    margin-left: 624px;
    margin-top: -100px;
}

.notFound a:hover{
    color: var(--yellow-color);
}