.counterSec{
    width: 100px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--green-color) !important;
    overflow: hidden;
    padding: 10px;
    position: relative;
  }
  
  .counterSec input{
    width: 60px;
    height: 30px;
    padding: 5px ;
    border: 0px;
    outline: none !important;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  
  .counterSec .arrow{
    position: absolute;
    cursor: pointer;
    opacity: 0.5;
  }
  
  .counterSec .arrow.plus{
    top:0px;
    right:10px;
  
  }
  
  .counterSec .arrow.minus{
    bottom: 0px;
    right: 10px;
  }