.newsletter .newsletter-inner {
  background: url(../../assets/img/banner-10.png) no-repeat center;
  background-size: cover;
  padding: 84px 78px;
  clear: both;
  display: table;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  min-height: 230px;
  margin-top: 50px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
h2 {
  font-size: 40px;
  color: var(--white-color);
  font-weight: 600;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: capitalize;
  z-index: 9;
}

.newsletter .newsletter-inner img {
  position: absolute;
  right: 50px;
  bottom: 0;
  max-width: 40%;
}

.newsletter .newsletter-inner .newsletter-content form input {
  border: 0;
  border-radius: 50px 0 0 50px;
  padding-left: 58px;
  z-index: 9;
  /* background: url(../imgs/theme/icons/icon-plane.png) no-repeat 25px center; */
}

.newsletter .newsletter-inner .newsletter-content form button {
  border-radius: 0px 20px 20px 0px;
  font-weight: 700;
  background-color: var(--yellow-color);
  color: var(--green-color);
  z-index: 9;
}

.newsletter .newsletter-inner .newsletter-content form button:hover {
  background-color: var(--sky-color);
}

@media (max-width: 768px) {
  .newsletter .newsletter-inner img {
    right: 8px;

    max-width: 60%;
  }
 
}

@media (max-width: 576px){
  .newsletter .newsletter-inner {
    width: 100%;

  }
  .newsletter-content{
    margin-left: -62px;
  }
  .mb-20 {
    font-size: 20px;
    display: flex;
}
.form-subcriber{
  width: 0px;
}
  .newsletter .newsletter-inner img {
    right: 100px;
  }
  .newsletter .newsletter-inner .newsletter-content form input {
    padding-left: 10px;
}
.newsletter .newsletter-inner .newsletter-content form button {
  font-size: 10px;
}
}

@media (max-width: 390px){
  .newsletter .newsletter-inner {
    width: 100%;

  }
  .newsletter-content{
    margin-left: -62px;
  }
  .mb-20 {
    font-size: 20px;
    display: flex;
}
.form-subcriber{
  width: 0px;
}
  .newsletter .newsletter-inner img {
    right: 100px;
  }
  .newsletter .newsletter-inner .newsletter-content form input {
    padding-left: 10px;
}
.newsletter .newsletter-inner .newsletter-content form button {
  font-size: 10px;
}
}
