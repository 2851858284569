/* map section start here */
.contact .map-sec {
  position: relative;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 1px 8px 0px;
  margin-bottom: 25px;
}

.contact .map-sec .map {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}

.contact .map-sec .map iframe {
  height: 100%;
  width: 100%;
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
  border: none;
}

/* map section end here */

/* contact information start here */
.contact .contact-main {
  padding-right: 25px;
  padding-left: 25px;
}

.contact .border-title {
  position: relative;
  padding-bottom: 14px;
  margin-bottom: 25px;
  margin-top: 45px;
  font-weight: 600;
  font-size: 24px; /* Default font size */
  transition: all 0.3s ease-in-out; /* Add transition for smooth title effect */
}

.contact .border-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 80px;
  background-color: var(--green-color);
}

.contact .contact-para {
  font-size: 18px;
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out; /* Add transition for smooth opacity effect */
}

.contact .contact-feature {
  display: flex;
  gap: 30px;
  border-radius: 10px;
  border: 1px solid #ecf1f9;
  background: var(--white-color);
  box-shadow: 0px 9px 14px 0px #fbfbfb;
  padding: 30px;
  transition: all 0.3s ease-in-out;
}

.contact .contact-feature:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.contact .contact-feature:not(:last-child) {
  margin-bottom: 24px;
}

.contact .contact-feature .contact-feature-icon {
  height: 70px;
  width: 70px;
  flex: none;
  border-radius: 10px;
  line-height: 65px;
  background: rgba(13, 94, 244, 0.07);
  font-size: 30px;
  text-align: center;
  color: var(--green-color);
  transition: transform 0.3s ease-in-out; /* Add transition for smooth icon effect */
}

.contact .contact-feature:hover .contact-feature-icon {
  transform: translateY(-5px);
}

.contact .contact-feature .media-body {
  margin-bottom: -0.4em;
}

.contact .contact-feature .contact-feature_label {
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: var(--black-color);
  font-weight: 600;
  margin-top: -0.4em;
  margin-bottom: 5px;
}

.contact .contact-feature .contact-feature_link {
  font-family: var(--title-font);
  color: #4d5765;
  line-height: normal;
  display: block;
  text-decoration: none;
  margin-left: 20px;
  transition: color 0.3s ease-in-out; /* Add transition for smooth link effect */
}

.contact .contact-feature .contact-feature_link:hover {
  color: var(--yellow-color);
}
/* contact information end here */


/* contact form start here */
.contact .contact-form-wrap {
  background: url("../../../assets/img/contact-bg1.png");
  padding: 45px;
  border-radius: 15px;
  background-position: right center;
  background-size: cover;
  margin-top: 15px;
  margin-left: 20px;
  position: relative;
  z-index: 9;
}

.contact .contact-form-wrap .sub-title {
  font-size: 18px;
  font-weight: 500;
}

.contact .contact-form-wrap .border-title {
  font-size: 32px;
  font-weight: 650;
  margin-top: 25px;
  margin-bottom: 45px;
}

.th-btn {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  border: none;
  background: var(--yellow-color);
  border-radius: 5px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 15px 25px;
  box-shadow: 0px 0px 10px var(--sky-color);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
}

.th-btn:hover {
  color: var(--green-color);
  background-color: var(--sky-color);
}
/* contact form end here */

/* responsive start here */
@media (max-width: 1199px) {
  .contact-form-wrap {
    margin: 0;
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
 
}

@media (max-width: 576px) {
  .contact .contact-form-wrap {
    margin-left: -20PX;
    MARGIN-RIGHT: -20px;
  }
}

@media (max-width: 375px) {
  .contact-form-wrap {
    padding: 40px 30px;
    background-position: left center;
  }
  .contact-feature {
    display: block;
  }

  .contact-feature .contact-feature-icon {
    margin-bottom: 20px;
  }
}
