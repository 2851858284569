.container {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
  text-align: center;
  justify-content: center;
  margin: 65px auto;
  padding: 40px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.h1 {
  font-size: 32px;
  font-weight: 400;
  color: #333;
  margin-bottom: 20px;
}

.form {
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  width: 100%;
  outline: none;
  border-radius: 5px;
  padding: 12px 15px;
  font-size: 16px;
  color: black;
  font-weight: 300;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.input:focus {
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.button {
  background-color: #4caf50;
  border: none;
  padding: 12px 20px;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.link {
  text-decoration: none;
  color: #4caf50;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
  margin-top: 15px;
  display: inline-block;
}

.link:hover {
  color: #45a049;
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

@media (max-width: 576px) {
  .container {
      padding: 35px 15px; /* Slightly reduce padding */
      margin: 60px auto;
      max-width: 350px; /* Adjust max-width for better fit */
  }

  .h1 {
      font-size: 28px; /* Reduce font size for smaller screens */
  }

  .button {
      font-size: 16px; /* Adjust button font size */
  }

  .input {
      padding: 10px 12px; /* Reduce padding for input fields */
  }
}

@media (max-width: 450px) {
  .container {
      padding: 30px 10px; /* Further reduce padding */
      margin: 50px auto;
      max-width: 320px; /* Adjust max-width for smaller screens */
  }

  .h1 {
      font-size: 24px; /* Further reduce font size */
  }

  .button {
      font-size: 14px; /* Adjust button font size */
  }

  .input {
      padding: 8px 10px; /* Further reduce padding for input fields */
  }
}

@media (max-width: 320px) {
  .container {
      padding: 25px 10px; /* Further reduce padding for the smallest screens */
      margin: 40px auto;
      max-width: 100%; /* Allow full width for very small screens */
  }

  .h1 {
      font-size: 20px; /* Further reduce font size */
  }

  .button {
      font-size: 12px; /* Adjust button font size */
      padding: 10px 15px; /* Adjust padding */
  }

  .input {
      padding: 6px 8px; /* Further reduce padding for input fields */
      font-size: 14px; /* Adjust input font size */
  }
}
