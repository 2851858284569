.bannerSection{
    padding: 25px 0px;
}

.bannerSection .box{
    overflow: hidden;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
}

.bannerSection .box:hover img{
   transform: scale(1.1);
}


.banner-img .banner-text {
    position: absolute;
    top: 50%;
    z-index: 9;
    transform: translateY(-50%);
    padding: 0 35px;
}

.banner-img .banner-text h4 {
    font-weight: 700;
    margin-bottom: 0px;
    min-height: 100px;
}

.btn-bnr {
    display: inline-block;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 7px;
    border-radius: 4px;
    color: #fff;
    border: 1px solid transparent;
    background-color: #3BB77E;
    cursor: pointer;
    text-decoration: none;
    text-transform: none;
}

.btn-bnr:hover {
    text-decoration: none;
    color: var(--yellow-color);
}


/* Tablets and smaller devices (portrait) */
@media (max-width: 768px) {
    .bannerSection {
        padding: 20px 0;
    }

    .bannerSection .box {
        border-radius: 10px;
    }

    .banner-img .banner-text {
        padding: 0 20px;
    }

    .banner-img .banner-text h4 {
        font-size: 18px;
        min-height: 80px;
    }

    .btn-bnr {
        font-size: 12px;
        padding: 8px 5px;
    }

    /* Mobile View Specific Styles */
    .bannerSection {
        display: flex;
        flex-wrap: wrap;
        gap: 10px; /* Adjust spacing between boxes as needed */
    }

    .bannerSection .box {
        flex: 1 1 calc(100% - 10px); /* Full width minus gap for spacing */
        box-sizing: border-box;
    }

}

/* Mobile devices (landscape and portrait) */
@media (max-width: 576px) {
    .bannerSection {
        
        padding: 15px 0;
    }

    .banner-main{
        display: block;
    }

    .bannerSection .box {
        border-radius: 8px;
        margin-bottom: 10px;
    }

    .banner-img .banner-text {
        padding: 0 15px;
    }

    .banner-img .banner-text h4 {
        font-size: 16px; /* Further reduced font size */
        min-height: 60px;
    }

    .btn-bnr {
        font-size: 11px;
        padding: 6px 4px;
    }
}