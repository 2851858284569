/* .nav{
    width: 100%;
    height: 60px;
    margin: auto;
    border-top: 1px solid #056839;
    border-bottom: 1px solid #056839;
}

.nav .catTab{
    background-color: #056839;
    padding: 6px 15px !important;
    text-transform: capitalize;
    font-size: 16px !important;
}

.nav nav ul li button{
    text-transform: capitalize !important;
    padding: 10px 25px !important;
    margin-left: 10px;
    outline: none;
}

.nav nav ul li button a{
    text-decoration: none !important;
    color: rgba(0,0,0,0.8) !important;
    font-size: 16px !important;

}

.nav .part3 svg{
    font-size: 30px;
}
.nav .part3 .phNo{
    margin-left: auto;

}
.nav .part3 .phNo h3{
    font-size: 27px;
    
}
.nav .part3 .phNo p{
    font-size: 17px;
    
}
 */


 .nav {
    display: none; /* Hide by default */
    width: 100%;
    background-color: var(--gray-color);
    position: absolute;
    top: 90px;
    left: 0;
    z-index: 998;
    transition: all 0.3s ease;
    border-bottom: 1px solid #056839;
}

.nav.open {
    display: block; /* Show when the menu is toggled */
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 12px;
}

.nav li {
    display: block;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;

}

.nav li a {
    color: #333;
    text-decoration: none;
    font-size: 16px;
    outline: none;
}

.nav li a:hover {
    color: #056839;
}

.part3 {
    padding: 10px 20px;
    background-color: #056839;
    color: #fff;
}

.nav .part3 svg{
    font-size: 30px;
}
.nav .part3 .phNo{
    margin-left: auto;

}
.nav .part3 .phNo h3{
    font-size: 27px;
    
}
.nav .part3 .phNo p{
    font-size: 17px;
    
}

/* Display navigation on larger screens */
@media (min-width: 769px) {
    .nav {
        display: block; /* Show nav by default on desktop */
        position: static;
        background-color: transparent;
    }

    .nav ul {
        display: flex;
        justify-content: space-between;
    }

    .nav li {
        border: none;
        padding: 0;
    }

    .nav li a {
        font-size: 18px;
    }

    .part3 {
        background-color: transparent;
        color: #333;
    }

    .phNo .icon {
        font-size: 30px;
    }
}

