.homeProducts{
    padding: 25px 0px;
}

.homeProducts .filterTab li{
    margin-left: 20px;
}

.homeProducts .filterTab li a{
    color: #000;
    text-decoration: none;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    position: relative;
    font-weight: 500;
}

.homeProducts .filterTab li:hover a{
    color: green;
    top: -3px;
}

.homeProducts .productRow{ 
    display: flex;
    padding: 30px 0px;
    margin: 0px 0px;
    flex-wrap: wrap;
}

.homeProducts .productRow .item{
    width: 20%;
    height: auto;
    padding: 15px 15px;

}

.banner4{
    border-radius: 15px;
}

.homeProductsRow2 .proSlider .slick-slide{
    padding-right: 10px !important;
    margin-top: 11px;
}

.topProductsSection{
    padding: 35px 0px ;
}

.banner-img1 .banner-text {
    position: absolute;
    top: 50%;
    z-index: 9;
    transform: translateY(-50%);
    padding: 0 30px;
}

.banner-img1 .banner-text h4 {
    font-weight: 800;
    margin-bottom: 28px;
    margin-top: -170px;
    min-height: 100px;
    font-size: 28px;
}

.btn-bnr {
    display: inline-block;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 7px;
    border-radius: 4px;
    color: #fff;
    border: 1px solid transparent;
    background-color: #3BB77E;
    cursor: pointer;
    text-decoration: none;
    text-transform: none;
}

.btn-bnr:hover {
    text-decoration: none;
    color: var(--yellow-color);
}

@media (max-width: 576px) {
    .slick-slider{
        margin-left: 18px;
    }
}
@media (max-width: 420px) {
    .slick-slider{
        margin-left: 9px;
    }
}