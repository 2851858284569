.topSellingBox h3 {
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.topSellingBox h3:after {
  content: "";
  width: 100%;
  height: 3px;
  background: #ccc;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.topSellingBox h3:before {
  content: "";
  width: 30%;
  height: 3px;
  background: green;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 9;
}

.topSellingBox .items {
  padding: 20px 0px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.topSellingBox .items:hover {
  transform: translateY(-10px);
}

.topSellingBox .items .img {
  width: 30%;
}
/* img{
    border-radius: 10px;
} */

.topSellingBox .items .info {
  width: 70%;
}

.topSellingBox .items .info p {
  margin-bottom: 0px;
}

.topSellingBox .items .info a {
  text-decoration: none;
  color: #253d4e;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 576px) {
  .topProductsSection {
    /* display: none; */
  }
  .topSellingBox .items .info p {
    padding-left: 0px;
  }
}
