.productThumb {
  width: 100%;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  overflow: hidden;
  padding: 15px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.productThumb:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.productThumb .badge {
  position: absolute;
  left: 0;
  top: 0px;
  display: inline-block;
  background: #f74b81;
  z-index: 9;
  font-size: 12px;
  text-align: center;
  line-height: 1;
  border-radius: 15px 0 20px 0;
  color: #fff;
  padding: 9px 20px 10px 20px;
}

.productThumb .imgWrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.productThumb .imgWrapper img {
  height: 250px;
  transition: all 0.3s ease-in-out;
}

.productThumb:hover .imgWrapper img {
  transform: scale(1.2);
}

.productThumb .imgWrapper .overlay {
  position: absolute;
  top: 0px;
  height: 100%;
  padding: 25px;
  display: flex;
  opacity: 0;
  transform: scale(0.8);
}

.productThumb:hover .imgWrapper .overlay {
  opacity: 1;
  transform: scale(1);
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  right: 0px;
  align-items: center;
}

.productThumb .imgWrapper .overlay ul {
  margin-left: 35px;
  width: 100px;
  background: #fff;
  height: auto;
  border-radius: 5px;
  border: 1px solid #bce3c9;
}

.productThumb .imgWrapper .overlay ul li a {
  /* margin-right: 0px !important; */
  border-left: 1px solid #bce3c9;
  cursor: pointer;
  color: green;
}

.productThumb .imgWrapper .overlay ul li a:hover {
  color: yellow;
}

.productThumb .imgWrapper .overlay ul li a {
  display: block;
  padding: 10px;
}

.productThumb .catName {
  color: black;
  opacity: 0.8;
  font-size: 14px;
}

.productThumb .title {
  margin: 10px 0px;
}

.productThumb .title a {
  color: black;
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;
  line-height: 2px;
}

.productThumb button {
  font-size: 14px !important;
  color: #fff !important;
  padding: 5px 25px !important;
  outline: none;
  background-color: #00000018;
  text-transform: uppercase;
}

.productThumb button:hover {
  background: green !important;
  color: #fff !important;
}

/* Responsive Styles */

/* For screens up to 576px */
@media (max-width: 576px) {
  .productThumb {
    width: 210px;
    padding: 8px;
  }

  .homeProducts .productRow {
    margin-left: -3px;
  }

  .productRow .item2 {
    width: 45%;
  }

  .productThumb .imgWrapper img {
    height: 170%;
    width: 100%;
  }

  .productThumb .imgWrapper .overlay ul {
    width: 60px; /* Adjust overlay width for mobile devices */
  }

  .productThumb .title a {
    font-size: 14px; /* Adjust font size for mobile devices */
  }

  .productThumb button {
    font-size: 11px !important;
    padding: 4px 10px !important;
  }

  .homeProducts .productRow .item {
    width: 50%;
  }

  .productThumb .imgWrapper .overlay ul li a {
    padding: 0px;
  }
}

/* For screens up to 500px */
@media (max-width: 500px) {
  .productThumb {
    width: 190px;
  }

  .homeProducts .productRow {
    margin-left: -16px;
    margin-right: 18px;
  }

  .price {
    font-size: 14px;
  }

  .oldPrice {
    font-size: 10px;
  }
}

@media (max-width: 470px) {
  .productThumb {
    width: 185px;
  }
}

/* For screens up to 440px */
@media (max-width: 440px) {
  .productThumb {
    width: 155px;
  }

  .homeProducts .productRow {
    margin-left: -16px;
    margin-right: 18px;
  }

  .homeProducts .productRow {
    margin-left: -5px;
    margin-right: 18px;
  }

  .price {
    font-size: 10px;
  }

  .oldPrice {
    font-size: 8px;
  }
}

/* For screens up to 390px */
@media (max-width: 390px) {
  .homeProducts {
    display: none;
  }

}
