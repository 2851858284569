/* src/App.css */
.homepage {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.blog-main {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
}

.blog-main .blog-box{
    background-color: var(--sky-color);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
}

.blog-main article {
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;
}

.blog-main .post-thumb {
    overflow: hidden;
    position: relative;
}

.blog-main .post-thumb .blogImg{
    max-width: 100%; 
    border-radius: 15px;  
}


.post-thumb .entry-meta {
    position: absolute;
    top: 10px;
    left: 8px;
    z-index: 2;
}

.post-thumb .entry-meta  {
    display: inline-block;
    background-color: var(--yellow-color);
    border-radius: 4px;
    width: 32px;
    height: 70px;
    text-align: center;
    line-height: 32px;
}

.blog-main.entry-content-2 {
    padding: 20px 30px;
}
.cat-left{
    margin-left: 12px;
    
    
}
.cat-left a{
    color: var(--darkgray-color);
    text-decoration: none;
}

.cat-left a:hover{ 
    color: var(--green-color);
    text-decoration: none;
}

.date-right{
    margin-left: 100px;
    color: var(--darkgray-color);
}

.blog-main .entry-content-2 .post-title{
    font-weight: 700;
    font-size: 25px;
}

.blog-main .entry-content-2 .post-title a{
    text-decoration: none;
    color: var(--green-color);

}

.blog-main .entry-content-2 .post-title a:hover{
    color: var(--brown-color);
}

.blog-desc p{
    text-align: justify;
    padding-left: 12px;
    padding-right: 12px;
}

@media (max-width: 576px) {
    .blog-main {
        margin-left: -38px;
        margin-right: 5px;
    }
    .date-right {
        margin-left: 330px;
    }
}

@media (max-width: 478px) {
    .blog-main {
        margin-left: -38px;
        margin-right: 5px;
    }
    .date-right {
        margin-left: 236px;
    }
}


