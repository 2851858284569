.footerSection {
  background: url("../../assets/img/footer_bg.png");
  background-size: cover;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
}

.footer-main {
  padding-left: 15px;
  padding-right: 15px;
}

.footerSection .footer-part1 img {
    margin-left: 20%;
    padding-top: -7px;
    width: 210px;
    height: 108px;
    padding-top: 35px;
}


.footerSection .footer-part1 .footer-body .footer-contact_text{
    color: var(--black-color);
    font-size: 18px;
    font-weight: 600;
}


.footerSection .footer-part1 .footer-body a{
    color: var(--white-color);
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    text-align: justify;
    cursor: pointer;
}


.footerSection .footer-part1 .footer-body a:hover{
    color: yellow;
}


.footer-part2 {
    padding-left: 100px;
    padding-top: 45px;
}

.footer-part2 .items-footer a{
    text-decoration: none;
    color: var(--white-color);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
}

.footer-part2 .items-footer a:hover{
    color: var(--yellow-color);
}


.widget_title{
    position: relative;
    border: none;
    font-family: var(--font-family);
    color: var(--white-color);
    line-height: 1;
    border-bottom: 0;
    padding: 0 0 18px 0;
    margin: -0.1em 0 30px 0;
    max-width: 275px;
}

.widget_title::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 51px;
    height: 3px;
    background-color: var(--yellow-color);
}

.widget_title::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 51px;
    height: 3px;
    width: 10px;
    left: 56px;
    background-color: var(--yellow-color);
    border-right: 10px solid var(--yellow-color);
    box-shadow: 15px 0 0 0 var(--yellow-color);
}

.footerSection .footer-part3 {
  /* margin-right: 25px; */
  padding-top: 45px;
}

.media-body{
    border-bottom: 1px dashed var(--white-color);
    /* padding: 20px; */
}

.footer-contact_text{
    color: var(--black-color);
    font-size: 16px;
    font-weight: 500;
}

.footer-contact_link a{
    color: var(--white-color);
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    display: block;
}

.footer-contact_link a:hover{
    color: var(--yellow-color);
}


.copywriteWrapper {
  border-top: 1px solid var(--lightyellow-color);
  padding: 20px 0;
}

.copywriteWrapper .copyright-text {
  margin-left: 25px;
  color: var(--white-color);
  font-size: 18px;
  font-weight: 500;
}

.copywriteWrapper .copyright-text a {
  color: var(--white-color);
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.copywriteWrapper .copyright-text a:hover {
  color: var(--yellow-color);
}

.copywriteWrapper img {
  width: 138px;
}

.copywriteWrapper .footer-social-links {
  text-align: end;
  margin-right: 25px;
}

.copywriteWrapper .footer-social-links a {
  color: var(--white-color);
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}

.copywriteWrapper .footer-social-links a:hover {
  color: var(--yellow-color);
}




/* Responsive Styles */

/* Desktop and Laptop (larger screens) */
@media (min-width: 992px) {
  .footer-part2 .items-footer {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Tablet and Mobile (medium and smaller screens) */
@media (max-width: 991px) {
  .footer-part2 .items-footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-part2 .items-footer a {
    margin-bottom: 15px;
  }

  .copywriteWrapper {
    flex-direction: column;
    text-align: center;
  }

  .copywriteWrapper .footer-social-links {
    justify-content: center;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .footerSection {
    background: url("../../assets/img/footer768.png");
    background-size: cover;
  }

  .footerSection .footer-part1 img {
    margin-left: 0%;
    
}
  .footer-part1 .footer-body a {
    font-size: 18px;
  }

  .footer-part2 {
    padding-left: 20px;
  
}

  .footer-part2 .items-footer a {
    font-size: 18px;
  }

  .footer-part3{
    font-size: 18px;
    padding: 20px;
  }

  .copywriteWrapper .copyright-text {
    font-size: 18px;
  }

  .copywriteWrapper .footer-social-links{
    margin-right: 140px;
  }

  .copywriteWrapper .footer-social-links a {
    font-size: 18px;
  }

}

@media (max-width: 576px) {
  .footerSection {
    background: url("../../assets/img/footer576n.png");
    background-size: cover;
  }
  .footer-part1 img {
    max-width: 180px;
  }

  .footerSection .footer-part1 img {
    margin-left: 30%;
    
}

  .footer-part1 .footer-body a {
    font-size: 16px;
  }

  .footer-part2 .items-footer a {
    font-size: 16px;
  }

  .copywriteWrapper .copyright-text {
    font-size: 16px;
  }

  .copywriteWrapper .footer-social-links{
    margin-right: 75px;
  }


  .copywriteWrapper .footer-social-links a {
    font-size: 16px;
  }
} 

@media (max-width: 390px) {
  .footerSection {
    background: url("../../assets/img/footer390.png") !important;
    background-size: cover;
  }
  .footer-part1 img {
    max-width: 180px;
  }

  .footerSection .footer-part1 img {
    margin-left: 20%;
    
}
.copywriteWrapper .copyright-text {
  margin-left: 20px;
  font-size: 15px;
  padding: 0px;
}
.copywriteWrapper .footer-social-links ul{
  margin-right: -52px;
}
.copywriteWrapper .footer-social-links ul li{
  margin-right: -5px;
}
.copywriteWrapper .footer-social-links a {
  font-size: 14px;
}
.pdesc{
  font-size: 15px;
}
.footer-part2 {
  padding-left: 46px;
  font-size: 15px;
}
.footer-widget{
  padding-left: 25px;
  font-size: 15px;
}
.footer-contact_text {
  font-size: 15px;
  margin-left: -20px;
}
.footer-contact_link a {
  font-size: 15px;
}
.footerSection .footer-part1 .footer-body .footer-contact_text {
  margin-left: 0px;
}
.footerSection .footer-part1 .footer-body .loc_link {
  margin-left: 22px;
}
.footerSection .footer-part1 .footer-body a {
  font-size: 15px;
}
}
