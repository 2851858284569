.homeSlider {
  padding: 25px 0px;
}

.slick-arrow {
  position: absolute;
  width: 40px !important;
  height: 40px !important;
  top: 50% !important;
  border-radius: 50% !important;
  z-index: 9;
  background-color: var(--green-color) !important;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
}

.slick-arrow.slick-next {
  right: 2% !important;
}

.slick-arrow.slick-next:hover {
  background-color: var(--gray-color);
}

.slick-next:before {
  content: "\f105";
  font-family: FontAwesome !important;
}

.slick-arrow.slick-prev {
  left: 2% !important;
}

.slick-arrow.slick-prev:hover {
  background-color: var(--gray-color);
}

.slick-prev:before {
  content: "\f104";
  font-family: FontAwesome !important;
}

.homeSliderMain .slick-dots {
  position: absolute;
  bottom: 30px !important;
}

.homeSliderMain .slick-dots button:before {
  font-size: 14px !important;
  width: 10px !important;
  height: 10px !important;
}

.slick-active button::before {
  color: var(--green-color) !important;
}

.item {
  position: relative;
}

.text-overlay {
  position: absolute;
  top: 25%;
  left: 8%;
  color: black;
  font-size: 50px;
  font-weight: 700;
  max-width: 600px;  /* Control break line */
  line-height: 1.2;
  word-break: break-word;
  white-space: normal;
}

/* Responsive Styles */

/* Desktop and Laptop (larger screens) */
@media (min-width: 992px) {
  .text-overlay {
    font-size: 50px;
    left: 8%;
  }
  
  .slick-arrow {
    width: 40px;
    height: 40px;
  }
  
  .homeSliderMain .slick-dots {
    bottom: 30px;
  }
  
  .homeSliderMain .slick-dots button:before {
    font-size: 14px;
  }
}

/* Tablet (medium screens) */
@media (max-width: 991px) and (min-width: 768px) {
  .text-overlay {
    font-size: 40px;
    left: 5%;
  }
  
  .slick-arrow {
    width: 35px;
    height: 35px;
  }
  
  .homeSliderMain .slick-dots {
    bottom: 25px;
  }
  
  .homeSliderMain .slick-dots button:before {
    font-size: 12px;
  }
}

/* Mobile (small screens) */
@media (max-width: 767px) {
  .text-overlay {
    font-size: 30px;
    left: 3%;
    top: 20%;
  }
  
  .slick-arrow {
    width: 30px;
    height: 30px;
  }
  
  .homeSliderMain .slick-dots {
    bottom: 20px;
  }
  
  .homeSliderMain .slick-dots button:before {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .text-overlay {
    font-size: 25px;
    left: 3%;
    top: 25%;
  }
  .slick-arrow {
    width: 25px !important;
    height: 25px !important;
  }
  .imgsli{
    width: 100% !important;
    height: 240px !important;
    
  }
}
