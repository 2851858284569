.cartSection {
    width: 100%;
    height: auto;

}

.cartSection .clearCart{
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.cartSection .clearCart svg{
    font-size: 22px !important;
}

.cartWrapper .table thead{
 background:#ececec;
 overflow: hidden;
}

.cartWrapper .table tbody td{
    vertical-align: middle;
}

.cartWrapper .table tbody td .cartimage{
    width: 90px;
    border: 1px solid grey;
    border-radius: 15px;
    /* padding: 10px; */
}

.cartWrapper .table tbody td .info a{
    color: rgba(0,0,0,0.8);
    text-decoration: none;

}

.cartWrapper .table tbody td .info a:hover{
    color: var(--green-color);
}

.cartWrapper .table tbody td .subTotal{
    font-weight: 600;
    font-size: 20px;
}

.cartWrapper .table tbody td svg{
    font-size: 20px;
    text-align: center;
}

.priceDetails{
    padding: 15px;
    background: var(--sky-color);
    border-radius: 10px;
    box-shadow: 0px 3px 10px var(--lightyellow-color);
    
}

.priceDetailsTitle {
    display: block;
    text-transform: uppercase;
    padding: 13px 24px;
    border-bottom: 1px solid var(--green-color);
    font-weight: 700;
}

.priceDetailsBody {
    padding: 0 24px;
}

.priceBody {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 20px 0;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.totalAmountBody {
    border-top: 1px dashed var(--gray-color);
    font-weight: 500;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


.savePrice {
    border-top: 1px dashed var(--gray-color);
    padding: 12px 0;
    font-size: 16px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: var(--green-color);
    font-weight: 500;
}

.btn-cart{
    font-size: 20px;
    font-weight: 600;
    outline: none;
}
