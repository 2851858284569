.featuredSection {
  padding: 45px 0px;
  width: 100%;
}

.featuredSection .featuredBoxes .box {
  background: var(--sky-color);
  padding: 15px 25px;
  border-radius: 15px;
}

.featuredSection .featuredBoxes .box img {
  height: 60px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.featuredSection .featuredBoxes .box:hover img {
  transform: translateY(-5px);
}

.featuredSection .featuredBoxes .box .info {
  padding-left: 25px;
}

.featuredSection .featuredBoxes .box .info h3 {
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .featuredSection .featuredBoxes .box {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .featuredSection .featuredBoxes .box {
    margin-bottom: 15px;
  }
}
