.blog-detail-heading{
    color: black;
    margin-top: 30px;
    margin-left: 0px;
}


.single-header-cat-left a{
    color: var(--darkgray-color);
    text-decoration: none;
}

.single-header-cat-left a:hover{
    color: var(--green-color);
    text-decoration: none;
}

.single-header-date-right{
    margin-left: 25px;
    color: var(--darkgray-color);
}

.single-thumbnail {
    margin-bottom: 30px;
}

.single-thumbnail img {
    border-radius: 15px;
    overflow: hidden;
    width: 1000px;
    height: 450px;
    margin-top: 30px;
}

.details-desc{
    text-align: justify;
}

/* Styles for laptops and desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .single-thumbnail img {
        width: 800px;
        height: 400px;
    }
}

/* Styles for tablets */
@media (min-width: 768px) and (max-width: 991px) {
    .single-header-date-right {
        margin-left: 15px;
    }

    .single-thumbnail img {
        width: 700px;
        height: 350px;
    }
}

/* Styles for mobile phones */
@media (max-width: 767px) {
    .blog-detail-heading {
        font-size: 24px;
    }

    .single-header-cat-left a {
        font-size: 14px;
    }

    .single-header-date-right {
        margin-left: 10px;
        font-size: 14px;
    }

    .single-thumbnail img {
        width: 100%;
        height: auto;
        margin-top: 15px;
    }

    .details-desc {
        font-size: 14px;
    }
}